* {
  margin: 0px;
  padding: 0px;
}

html, body {
  color: #444446;
  font-size: 62.5%;
  font-family:  'Noto Sans JP', '游ゴシック体', 'Yu Gothic', YuGothic, sans-serif;
  font-style: normal;
  font-feature-settings: 'palt';
  box-sizing: border-box;
  background-color: #fff;
}




@media screen and (max-width: 480px) {
  /* 全要素の余白 */
  .container {
    padding: 0 40px;
    margin: 0 auto;
  }


  .area_home_main , .area_home_about , .area_home_merit , .area_home_feature , .area_home_cost, .area_home_step{
      padding: 50px 0;
      width: 100%;
    }
    .main_anime , .merit1 , .merit2 , .merit3 , .img_push {
      width: 60%;
      height: auto;
    }
    .img_stamp , .img_coupon , .img_point , .img_photo , .img_extra {
      width: 95%;
      height: auto;
    }
    .customize_screen {
      
      width: 90%;
      height: auto;
    }
    .container {
      text-align: center;
    }
    
    .ttl_eng {
      font-size: 5rem;
    }
    .ttl_ja {
      font-size: 1.5rem;
      padding: 0 0 20px 0;
    }
    
    /*  ボディ MAIN */
    .main_text {
      width: 80%;
      height: auto;
    } 
    .main_anime {
      margin-top: 40px;
    }
    
    /*  ボディ ABOUT */
    .body_about_txt {
      padding: 30px 0 0 0;
      font-size: 35px; 
    }
    .body_about_smalltxt {
      font-size: 15px;
    }
    
    /*  ボディ MERIT */
    .merit1 , .merit2  .merit3{
      margin-top: 20px;      
    }
    /*  ボディ FEATURE */
    .body_feature { 
      display:flex;
      justify-content: center;
      flex-direction:column;
      margin-bottom: 60px;
    }
    .feature_contents , .feature_contents0 , .feature_contents1 > img {
      text-align: center;
      margin: auto;
    }
    .feature_ttl{
      font-size: 50px;
    }
    .feature_txt{
      font-size: 5px;
      margin-top: 8px;
    }
    .feature_contents0 {
      order: 0;
    }
    .feature_contents1 {
      order: 1 ;
    }
    .feature_contents , .feature_contents0 , .feature_contents1 > img{
      margin-top: 20px;
    }
    
    /* ボディ　COST */
    .cost_ttl{
      line-height: 40px;
      font-size: 25px;
      padding-bottom: 20px;
    }
    .cost_ttl > b{
      font-size: 40px;
    }
    .body_cost_ttl > span{
      font-size: 2rem;
    }
    .body_cost {
      width: 100%;
    }
    
    /* ここから中央寄せにする */
    .body_cost_txt{
      margin: 20px 0; 
    }
    
    .menu_image {
      overflow: scroll;
    }
    .menu {
      margin-top: 30px;
      height: 400px;
      width: auto;
    }
    
    /* ボディ　STEP */
    .step_contents > h2 {
      padding-top: 5px;
    }
    .btn_contact_link{
      margin: 10px auto 0;
    }
    


    /* ヘッダ */
    header {
      position: relative;
      padding: 20px 0 0 0px;
      background-color: black;
      height: 51px;
    }
    .area_logo_header {
      width: 140px;
      margin: 0 auto;
    }
    
    .logo_image {
      height: auto;
      width: 150px;
    }    
    /*ハンバーガーアイコン*/
    .btn-burger {
        cursor: pointer;
        display: block;
        width: 56px;
        height: 60px;
        position: absolute;
        top: 5px;
        right: 10px;
        background-color: white;
    }

    /*ハンバーガーアイコンを作る三本線*/
    .icon, .icon:before, .icon:after {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        height: 3px; /*線の太さ*/
        width: 35px; /*線の長さ*/
        background-color: black;
        border-radius: 2px;
        display: block;
        content: '';
        cursor: pointer;
        margin: auto;
    }
    /*三本線の間隔*/
    .icon:before {
        top: 20px;
    }
    .icon:after {
        top: -20px;

    }


    /*アイコンをクリックしたら*/
    .nav-toggle:checked ~ .btn-burger .icon {
        background: transparent;
    }
    .nav-toggle:checked ~ .btn-burger .icon:before {
        transform: rotate(-45deg);
        top: 0;
    }
    .nav-toggle:checked ~ .btn-burger .icon:after {
        transform: rotate(45deg);
        top: 0;
    }
    .icon,
    .icon:before,
    .icon:after {
        transition: all .8s;
    }


    /*表示されるメニューの中身*/
    .area_nav_header {
        background-color: black;        
    }
    .list_nav_header a {
        display: block;
        text-decoration: none;
        color: #fff;
    }
    .list_nav_header {
        list-style: none;
        display: none;
        margin: 0;
        padding-left: 20px;
    }
    .list_nav_header li {
        margin: 0;
        padding: 10px;
    }
    .nav-toggle:checked ~ .area_nav_header .list_nav_header {
        display: block;
    }
}


@media screen and (min-width: 480px) {
    /* 全要素の余白 */
    .container , .container_header {
      padding: 0 40px;
      margin: 0 auto;
    }

    .area_home_main , .area_home_about , .area_home_merit , .area_home_feature , .area_home_cost, .area_home_step{
      padding: 80px 0;
      width: 100%;
    }
    /* 画像サイズ */
    .logo_image {
      height: 40x;
      width: auto;
    }
    .main_text , .customize_screen , .img_push , .img_coupon , .img_point , .img_photo {
      height: 500px;
      width: auto;
    }
    .main_anime {
      height: 600px;
      width: auto;
    }
    .merit1 , .merit2 , .merit3 {
      height: auto;
      width: 300px;
    }
    .img_extra {
      height: auto;
      width: 80%;
    }
    .menu {
      height: auto;
      width: 500px;
    }

    .ttl_eng {
      font-size: 12rem;
    }
    .ttl_ja {
      font-size: 2.4rem;
      padding: 30px 0 0 30px;
    }

    /* ボディ　タイトル */
    .head_home {
      text-align: center;
      margin-bottom: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .body_contents {    
      display: flex;
      justify-content: center; 
    }
    
    /* ヘッダ */
    .header {
      background-color: black;
      /* スクロール時の固定 */
      position: fixed;
      width: 100%;
    }
    .header > .container_header {
      height: 80px;
      display: flex;
      align-items: center;
    }
    .area_logo_header .logo {
      display: block;
    }
    .area_logo_header .logo img {
      height: 30px;
      vertical-align: bottom;
    }

    /* ナビゲーション */
    .area_nav_header {
    margin-left: auto;
    }

    /* リストナビ */
    .list_nav_header {
    list-style: none;
    display: flex;
    align-items: center;
    }
    .list_nav_header a {
    margin-left: 30px;
    text-decoration: none;
    color: white;
    font: 600 15px/1em '游ゴシック体', 'Yu Gothic', YuGothic, sans-serif ;

    /* レスポンシブ用に追加 */
    display: block;
    }

    .list_nav_header  .btn_contact {
    color: black;
    display: flex;
    align-items: center;
    height: 36px;
    padding: 0 24px;
    background: rgb(245, 197, 30);
    border-radius: 2px;
    }
  

    /* ボディ MAIN*/
    .body_goods {
      margin-right: 120px;
    } 
    /* ヘッダ固定した分の余白下げ  */
    .body_goods , .main_anime {
      margin-top: 80px;
    }
    /* ボディ　ABOUT */
    .body_about_txt {
      font-size: 5.5rem;
    }
    .body_about_smalltxt {
      font-size: 2.5rem;
    }
    
    /* ボディ FEATURE */
    .feature_contents , .feature_contents0 , .feature_contents1 { 
      /* 縦に中央揃え */
      display:flex;
      justify-content: center;
      flex-direction:column;
    }
    .body_feature{
      margin: 150px auto;
      display: flex;
      justify-content: space-around;
    }
    .feature_ttl{
      font-size: 60px;
    }

    /* ボディ　COST */
     .body_cost{
      display: flex;
      justify-content: space-around;
      padding-bottom: 50px;
    }
    .cost_ttl{
      line-height: 80px;
      font-size: 45px;
      padding-bottom: 20px;
    }
    .cost_ttl > b{
      font-size: 80px;
    }
    .body_cost_ttl > span{
      font-size: 2.5rem;
    }
    .menu {
      height: auto;
      width: 90%;
    }

    /* ボディ　STEP */
    .step_contents > h2 {
      padding-top: 20px;
    }
    .menu_image{
      display: flex;
      justify-content: center;
      width: 100%;
    }
    .btn_contact_link{
      margin: 80px auto 0;
      width: 30%;
    }
}







/* 共通 */
/* 共通 */
/* ボディ 背景色*/

.area_home_main , .area_home_merit , .area_home_step{
  background:rgb(245, 197, 30);
}
.area_home_about {
  background: linear-gradient(180deg, #efefeb 20%, #fff 90%);
}
.area_home_cost {
  background:blanchedalmond;
}

/* ボディ　タイトル */
.ttl_eng {
  color: #fff5dd;
  line-height: 1;
  letter-spacing: 0px;
  text-transform: uppercase;
  font-weight: 350;
}
.ttl_ja {
  font-weight: bold;
  letter-spacing: 0.24em;
  /* justify-content: center; */
}

/* ボディ MAIN*/
.btn_design_gallery {
  display: flex;
  justify-content: center;
  text-decoration: none;
  text-align: center;
  color: rgb(245, 197, 30);
  width: 70%;
  margin: 30px  auto;
  padding:  20px 50px ;
  background: black;
  border-radius: 10px;
  font: 600 22px/1em '游ゴシック体', 'Yu Gothic', YuGothic, sans-serif ;
}
.btn_design_gallery:hover , .btn_contact_link:hover {
  background-color:white;
  color: black;
}

/* ボディ　ABOUT */
.body_about_txt {
  line-height: 1.5em;
  font-weight: 500;
  margin-bottom: 30px;
  letter-spacing: 5px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.body_about_smalltxt {
  line-height: 2em;
  font-weight: bold;
  letter-spacing: 5px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
/* ボディ FEATURE */
.feature_contents_center{
  text-align: center;
}
.body_feature_txt , .feature_txt {
  line-height: 1.5em;
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.body_feature_txt {
  font-size: 25px;
}
.feature_txt{
  font-size: 16px;
  margin-top: 20px;
}
.push_color, .stamp_color, .coupon_color, .point_color, .photo_color{
  border-radius: 70px ;
  margin: 5px 0;
  padding: 6px 0;
  font-size: 15px;
  font-weight: bold;
}

/* 小見出し背景色 */
.push_color, .point_color{
  background-color: #f5d61e;
  color: #000;
}
.point_color{
  text-align: center;
}
.stamp_color, .photo_color{
  background-color: #0000ff;
  color:#ffffff;
  text-align: center;
}
.coupon_color{
  background-color: #c30d23;
  color:#ffffff;
  text-align: center;
}

/* ボディ　COST */
.cost_ttl > b{
  vertical-align: -5px;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}
.body_cost_ttl > span{
  font-weight: bold;
}
.pricebox{
  background-color: rgb(245, 197, 30);
  width: 250px;
  height: 250px;
  
  /* display: table-cell;
  vertical-align: middle; */
  text-align: center;
  margin:  auto;
}
.price2 {
  font-size: 20px;
}
.pricetxt{
  font-size: 25px;
  font-weight: bold;
  line-height: 70px;
}
.price{
  display: block;
  font-size: 30px;
  font-weight: bold;
}
.price > b {
  font-size: 70px;
  letter-spacing: -0.2rem;
}

/* ボディ　STEP */
.btn_contact_link{
  display: flex;
  justify-content: space-around;
  text-decoration: none;
  text-align: center;
  color: rgb(245, 197, 30);
  padding:  20px 50px ;
  background: black;
  border-radius: 10px;
  font: 600 22px/1em '游ゴシック体', 'Yu Gothic', YuGothic, sans-serif ;
}
.step_contents{
  padding: 20px;
}
.step_contents > h2 {
  font-size: 25px;
  text-align: center;
}
/* フッタ */
footer{
  background: rgb(245, 197, 30);
}
footer > .container{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.list_sns{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -10px;
}
.list_sns > li {
  padding: 50px 10px 10px;
  list-style: none;
}
.list_sns a {
  display: block;
  width: 40px;
  height: 40px;
  background: #000;
  border-radius: 50%;
  position: relative;
}

.list_sns a:before{
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate( -50% , -50% );
}

.list_sns .instagram a:before{
  background: url(img/ico/ico_sns_instagram.svg);
}
.list_sns .facebook a:before{
  background: url(img/ico/ico_sns_facebook.svg);
}

.copyright{
  font: 300 14px/1rem 'furura PT' , 'noto sans japanise' , sans-serif;
  margin: 30px 0 30px;
}

.nav-toggle {
  display: none;
}